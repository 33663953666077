import { useEffect, useState } from "react";
import { Row, Col, Table, Button } from "antd";

import { GET } from "../../../../providers/useAxiosQuery";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../../providers/CustomTableFilter";
import ModalFormExamORNumber from "./ModalFormExamORNumber";

export default function TableApproval(props) {
	const { tableFilter, setTableFilter, location, params } = props;

	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [
		toggleModalFormExamORNumber,
		setToggleModalFormExamORNumber,
	] = useState(false);

	const {
		data: dataStudentExam,
		refetch: refetchStudentExam,
	} = GET(
		`api/student_exams?${new URLSearchParams(tableFilter)}`,
		"student_exams_list",
		(res) => {}
	);

	useEffect(() => {
		refetchStudentExam();

		return () => {};
	}, [tableFilter]);

	useTableScrollOnTop("tblApproval", location.pathname);

	return (
		<>
			<Row gutter={[12, 12]} id="tbl_wrapper_approval">
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-top-filter">
						<div style={{ display: "flex", gap: 8 }}>
							<Button
								className="btn-main-primary"
								disabled={selectedRowKeys.length === 0}
								onClick={() => {
									setToggleModalFormExamORNumber(true);
								}}
							>
								Update Record
							</Button>

							<TableGlobalSearchAnimated
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
						<div style={{ display: "flex", gap: 12 }}>
							<TableShowingEntriesV2 />
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<Table
						id="tblApproval"
						className="ant-table-default ant-table-striped"
						dataSource={dataStudentExam && dataStudentExam.data.data}
						rowKey={(record) => record.id}
						pagination={false}
						bordered={false}
						scroll={{ x: "max-content" }}
						sticky
						rowSelection={{
							selectedRowKeys,
							onChange: (selectedRowKeys) => {
								setSelectedRowKeys(selectedRowKeys);
							},
						}}
					>
						<Table.Column
							title="Approval Status"
							key="schedule_status"
							dataIndex="schedule_status"
							width={150}
						/>

						<Table.Column
							title="Exam Category"
							key="exam_category_id"
							dataIndex="exam_category_id"
							sorter={(a, b) => a.exam_category - b.exam_category}
							render={(text, record) => {
								return <>{record.exam_category}</>;
							}}
							width={150}
						/>

						<Table.Column
							title="Exam Fee"
							key="exam_fee"
							dataIndex="exam_fee"
							sorter={(a, b) => a.exam_fee - b.exam_fee}
							width={100}
							render={(text, record) => {
								return (
									<>
										₱{" "}
										{record.exam_fee
											? parseFloat(record.exam_fee).toFixed(2)
											: "N/A"}
									</>
								);
							}}
						/>

						<Table.Column
							title="Student Year Level"
							key="student_level"
							dataIndex="student_level"
							sorter={(a, b) => a.student_level.localeCompare(b.student_level)}
							width={200}
						/>

						<Table.Column
							title="Student Status"
							key="student_status"
							dataIndex="student_status"
							sorter={(a, b) =>
								a.student_status.localeCompare(b.student_status)
							}
							width={190}
						/>

						<Table.Column
							title="Student Name"
							key="fullname"
							dataIndex="fullname"
							sorter={(a, b) => a.fullname.localeCompare(b.fullname)}
							width={250}
						/>

						<Table.Column
							title="Personal Email"
							key="personal_email"
							dataIndex="personal_email"
							sorter={(a, b) =>
								a.personal_email.localeCompare(b.personal_email)
							}
							width={250}
						/>
					</Table>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-bottom-filter">
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							setPaginationTotal={dataStudentExam?.data?.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper_approval"
						/>
					</div>
				</Col>
			</Row>

			<ModalFormExamORNumber
				toggleModalFormExamORNumber={toggleModalFormExamORNumber}
				setToggleModalFormExamORNumber={setToggleModalFormExamORNumber}
				dataSource={dataStudentExam}
				tableFilter={tableFilter}
				selectedRowKeys={selectedRowKeys}
				setSelectedRowKeys={setSelectedRowKeys}
				exam_schedule_id={params.id}
			/>
		</>
	);
}
