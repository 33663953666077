import React, { useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import moment from "moment-timezone";
import ModalFormEntranceExamSchedule from "./components/ModalFormEntranceExamSchedule";
import dayjs from "dayjs";

export default function PageEntranceExamCalendar(props) {
	const { dataSource } = props;

	moment.tz.setDefault("Asia/Manila");
	const calendarRef = useRef(null);

	const [
		toggleModalFormEntranceExamSchedule,
		setToggleModalFormEntranceExamSchedule,
	] = useState({
		open: false,
		data: null,
	});

	let eventCount = 0;

	const eventData = dataSource.data.data.map((item) => {
		const {
			exam_date,
			total_available_slot,
			slots,

			time_in,
			time_in_meridiem,
			time_out,
			time_out_meridiem,

			sy_from,
			sy_to,
			semester_id,
		} = item;

		return {
			title: `Exam Schedule  ${total_available_slot} / ${slots}`,
			time: `( ${time_in} ${time_in_meridiem} - ${time_out} ${time_out_meridiem} )`,
			start: moment(`${exam_date} ${time_in}`, "YYYY-MM-DD hh:mm A").format(
				"YYYY-MM-DD HH:mm:ss"
			),
			end: moment(`${exam_date} ${time_out}`, "YYYY-MM-DD hh:mm A").format(
				"YYYY-MM-DD HH:mm:ss"
			),
			color: total_available_slot === 0 ? "#FE0000" : "green",
			classNames: total_available_slot === 0 ? "red" : "green",

			item: item,
		};
	});

	const currentDay = new Date().toLocaleString("en-us", {
		weekday: "short",
	});

	const onSelectDate = (arg) => {
		setToggleModalFormEntranceExamSchedule({
			open: true,
			data: {
				exam_date: arg.startStr,
				time_in: dayjs(arg.startStr)
					.set("hour", 8)
					.set("minute", 0)
					.format("hh:mm"),
				time_out: dayjs(arg.startStr)
					.set("hour", 9)
					.set("minute", 0)
					.format("hh:mm"),
				time_in_meridiem: dayjs(arg.startStr).format("A"),
				time_out_meridiem: dayjs(arg.endStr).format("A"),
			},
		});
	};

	return (
		<>
			<div className="container">
				<FullCalendar
					// ref to the calendar to access the calendar object
					ref={calendarRef}
					height={650}
					dayMaxEvents={true}
					displayEventTime={false}
					events={eventData}
					eventContent={(arg) => {
						const { event } = arg;
						const { title } = event._def;
						const { color } = event._def.ui;
						const { time } = event._def.extendedProps;

						return (
							<div
								className="event-content"
								style={{
									backgroundColor: color,
									borderRadius: "5px",
									padding: "5px",
									color: "white",
								}}
							>
								{title}
								<br />
								{time}
							</div>
						);
					}}
					plugins={[
						dayGridPlugin,
						timeGridPlugin,
						interactionPlugin,
						multiMonthPlugin,
					]}
					headerToolbar={{
						left: "prev,today,next",
						center: "title",
						right: "dayGridMonth,timeGridWeek,timeGridDay",
					}}
					views={{
						dayGridMonth: {},
						timeGridWeek: {
							nowIndicator: true,
							slotMinTime: "06:00:00",
							slotMaxTime: "22:00:00",
						},
						timeGridDay: {
							nowIndicator: true,
							slotMinTime: "06:00:00",
							slotMaxTime: "22:00:00",
						},
					}}
					eventDidMount={({ event }) =>
						moment(event.start).isSame(new Date(), "day") && eventCount++
					}
					datesSet={({ startStr, endStr, args }) => {
						document
							// highlight the current day, by adding a class to the header
							.querySelectorAll(".fc-col-header-cell")
							.forEach(
								(header) =>
									header.textContent === currentDay &&
									header.classList.add("current-day-header")
							);

						const start = new Date(startStr);

						document.querySelectorAll(".fc-day").forEach((cell, index) => {
							const date = new Date(
								start.getTime() + index * 24 * 60 * 60 * 1000
							);

							if (calendarRef.current) {
								const eventsOnThisDay = calendarRef.current
									.getApi()
									.getEvents()
									.filter((event) => event.start.getDate() === date.getDate());
								if (eventsOnThisDay.length > 0) {
									const badge = Object.assign(document.createElement("badge"), {
										className: "badge",
									});

									// Reset event count
									const countTextElement = cell.querySelector(".countText");
									const badgeElement = cell.querySelector(".badge");
									if (countTextElement) countTextElement.remove();
									if (badgeElement) badgeElement.remove();

									cell.append(badge);

									if (
										calendarRef.current.getApi().view.type !== "dayGridMonth"
									) {
										const countText = Object.assign(
											document.createElement("span"),
											{
												className: "countText",
												textContent: ` ${eventsOnThisDay.length} Schedule`,
											}
										);
										cell.append(countText);
									}
								}
							}
						});
					}}
					selectable={true}
					select={onSelectDate}
					eventClick={(arg) => {
						let item = arg.event.extendedProps.item;

						setToggleModalFormEntranceExamSchedule({
							open: true,
							data: item,
						});
					}}
				/>
			</div>

			<ModalFormEntranceExamSchedule
				toggleModalFormEntranceExamSchedule={
					toggleModalFormEntranceExamSchedule
				}
				setToggleModalFormEntranceExamSchedule={
					setToggleModalFormEntranceExamSchedule
				}
			/>
		</>
	);
}
