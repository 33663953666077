import { useEffect, useState } from "react";
import {
	Modal,
	Form,
	Row,
	Col,
	Button,
	notification,
	Table,
	Popconfirm,
} from "antd";

import dayjs from "dayjs";
import FloatInput from "../../../../providers/FloatInput";

import validateRules from "../../../../providers/validateRules";
import ModalAuthPassword from "./ModalAuthPassword";

export default function ModalFormExamORNumber(props) {
	const {
		toggleModalFormExamORNumber,
		setToggleModalFormExamORNumber,
		dataSource,
		tableFilter,
		selectedRowKeys,
	} = props;

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const [form] = Form.useForm();
	const [copyDataSource, setCopyDataSource] = useState([]);
	const [removeSelectedRow, setRemoveSelectedRow] = useState([]);

	// Update the OR Number input field
	const handleInputBlur = (e, record, field) => {
		let newDataSource = [...copyDataSource];
		let index = newDataSource.findIndex((data) => data.id === record.id);

		if (index !== -1) {
			const updatedItem = {
				...newDataSource[index],
				[field]: e.target.value,
			};

			newDataSource[index] = updatedItem;
			setCopyDataSource(newDataSource);
			form.submit();
		}
	};

	useEffect(() => {
		if (toggleModalFormExamORNumber) {
			setCopyDataSource(
				dataSource.data.data.filter((data) => selectedRowKeys.includes(data.id))
			);
			form.setFieldsValue({
				...toggleModalFormExamORNumber.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalFormExamORNumber]);

	return (
		<>
			<Modal
				width={1200}
				wrapClassName="modal-form-module"
				title="FORM EXAM SCHEDULE APPROVAL"
				open={toggleModalFormExamORNumber}
				onCancel={() => {
					setToggleModalFormExamORNumber(false);
					form.resetFields();
					setCopyDataSource([]);
				}}
				forceRender
				footer={[
					<Button
						className="btn-main-primary outlined"
						key={1}
						onClick={() => {
							setToggleModalFormExamORNumber(false);
							form.resetFields();
							setCopyDataSource([]);
						}}
					>
						CANCEL
					</Button>,
					<Button
						className="btn-main-primary"
						type="primary"
						key={2}
						onClick={() => {
							const allOrNumbersFilled = copyDataSource.every(
								(item) => item.or_number && item.or_number.trim() !== ""
							);

							if (!allOrNumbersFilled) {
								notification.error({
									message: "Applicants",
									description: "Please fill up all OR Numbers",
								});
							} else {
								const data =
									copyDataSource &&
									copyDataSource.map((data) => {
										return {
											id: data.id,
											or_number: data.or_number,
											fullname: data.fullname,
											exam_schedule_id: data.exam_schedule_id,
											exam_category_id: data.exam_category_id,
										};
									});

								setToggleModalAuthPassword({
									open: true,
									data: data,
									tableFilter: tableFilter,
								});
								setToggleModalFormExamORNumber(false);
							}
						}}
						name="btn_submit"
					>
						SUBMIT
					</Button>,
				]}
			>
				<Form form={form}>
					<Row gutter={[12, 0]}>
						<Col>
							<Popconfirm
								title={
									<>
										Are you sure you want to
										<br />
										remove the selected <br />
										{removeSelectedRow.length > 1 ? "applicants" : "applicant"}?
									</>
								}
								okText="Yes"
								cancelText="No"
								onConfirm={() => {
									let newDataSource = copyDataSource.filter(
										(data) => !removeSelectedRow.includes(data.id)
									);
									setCopyDataSource(newDataSource);
									setRemoveSelectedRow([]);

									notification.success({
										message: "Applicants",
										description: "Selected record has been removed",
									});
								}}
							>
								<Button
									className="btn-main-primary ml-10"
									disabled={removeSelectedRow.length === 0}
								>
									Remove from selected
								</Button>
							</Popconfirm>
						</Col>

						<Col xs={24} sm={24} md={24} lg={24} className="table-content">
							<Table
								id="tblApproval"
								className="ant-table-default ant-table-striped"
								dataSource={copyDataSource}
								rowKey={(record) => record.id}
								pagination={false}
								bordered={false}
								scroll={{ x: "max-content" }}
								sticky
								rowSelection={{
									removeSelectedRow,
									onChange: (removeSelectedRow) => {
										setRemoveSelectedRow(removeSelectedRow);
									},
								}}
							>
								<Table.Column
									title="OR Number"
									key="or_number"
									dataIndex="or_number"
									width={200}
									render={(text, record, index) => (
										<Form.Item
											name={`or_number_${record.id}`}
											className="mb-0"
											rules={[validateRules.required]}
										>
											<FloatInput
												required={true}
												// defaultValue={text}
												onBlur={(e) => {
													handleInputBlur(e, record, "or_number");
												}}
												autoComplete="off"
												label="Input OR Number"
												placeholder="Input OR Number"
											/>
										</Form.Item>
									)}
								/>

								<Table.Column
									title="Student Year Level"
									key="student_level"
									dataIndex="student_level"
									sorter={true}
									width={200}
								/>

								<Table.Column
									title="Student Status"
									key="student_status"
									dataIndex="student_status"
									sorter={true}
									width={200}
								/>

								<Table.Column
									title="Student Name"
									key="fullname"
									dataIndex="fullname"
									sorter={true}
									width={200}
									render={(text, record) => {
										const fullname = record.fullname;
										return <>{` ${fullname} `}</>;
									}}
								/>

								<Table.Column
									title="Exam Schedule"
									key="exam_schedule_id"
									dataIndex="exam_schedule_id"
									sorter={true}
									width={300}
									render={(text, record) => {
										const date = dayjs(record.exam_date).format(
											"MMMM DD, YYYY"
										);
										const exam_time = record.exam_time;

										return <>{` ${date} (${exam_time})`}</>;
									}}
								/>
							</Table>
						</Col>
					</Row>

					<Row gutter={[12, 0]}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<p>Number of Applicants: {selectedRowKeys.length}</p>
						</Col>
					</Row>
				</Form>
			</Modal>

			<ModalAuthPassword
				toggleModalAuthPassword={toggleModalAuthPassword}
				setToggleModalAuthPassword={setToggleModalAuthPassword}
			/>
		</>
	);
}
