import { useEffect, useState } from "react";
import { Row, Col, Table, Button, Popconfirm } from "antd";

import { GET } from "../../../../providers/useAxiosQuery";
import {
	TableGlobalSearchAnimated,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../../providers/CustomTableFilter";

import ModalFormExamSheetNumber from "./ModalFormExamSheetNumber";
import ModalAuthPassword from "./ModalAuthPassword";

export default function TableExamStatus(props) {
	const { tableFilter, setTableFilter, location, params } = props;

	// Open Modal Form Exam Answersheet Number
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [
		toggleModalFormExamSheetNumber,
		setToggleModalFormExamSheetNumber,
	] = useState({
		open: false,
		data: null,
	});

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const {
		data: dataStudentExam,
		refetch: refetchStudentExam,
	} = GET(
		`api/student_exams?${new URLSearchParams(tableFilter)}`,
		"student_exams_list",
		(res) => {}
	);

	useEffect(() => {
		refetchStudentExam();

		return () => {};
	}, [tableFilter]);

	useTableScrollOnTop("tblExamResult", location.pathname);

	return (
		<>
			<Row gutter={[12, 12]} id="tbl_wrapper_examresult">
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-top-filter">
						<div style={{ display: "flex", gap: 8 }}>
							<Button
								onClick={() => {
									setTableFilter((ps) => ({
										...ps,
										exam_status: "Not Taken",
									}));
									setSelectedRowKeys([]);
								}}
								className={
									tableFilter.exam_status === "Not Taken" && "btn-main-primary"
								}
							>
								Not Taken
							</Button>

							<Button
								onClick={() => {
									setTableFilter((ps) => ({
										...ps,
										exam_status: "Taken",
									}));
									setSelectedRowKeys([]);
								}}
								className={
									tableFilter.exam_status === "Taken" && "btn-main-primary"
								}
							>
								Taken
							</Button>

							{selectedRowKeys.length > 0 && (
								<Popconfirm
									title={
										<>
											Are you sure you want to
											<br /> update the selected{" "}
											{selectedRowKeys.length > 1
												? "exam statuses"
												: "exam status"}
											<br />
											to{" "}
											{tableFilter.exam_status === "Not Taken"
												? "taken"
												: "checked"}
											?
										</>
									}
									okText="Yes"
									cancelText="No"
									disabled={selectedRowKeys.length === 0}
									onConfirm={() => {
										if (tableFilter.exam_status === "Not Taken") {
											setToggleModalFormExamSheetNumber({
												open: true,
												exam_schedule_id: params.id,
												ids: selectedRowKeys,
												status: tableFilter.exam_status,
											});
										} else {
											let data = dataStudentExam.data.data.filter((item) =>
												selectedRowKeys.includes(item.id)
											);

											setToggleModalAuthPassword({
												open: true,
												data: data,
												tableFilter: tableFilter,
											});
										}
									}}
								>
									<Button className="btn-main-primary outlined">
										Update Status to{" "}
										{tableFilter.exam_status === "Not Taken"
											? "Taken"
											: "Checked"}
									</Button>
								</Popconfirm>
							)}

							<TableGlobalSearchAnimated
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
						<div style={{ display: "flex", gap: 12 }}>
							<TableShowingEntriesV2 />
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</div>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<Table
						id="tblExamResult"
						className="ant-table-default ant-table-striped"
						dataSource={dataStudentExam && dataStudentExam.data.data}
						rowKey={(record) => record.id}
						pagination={false}
						bordered={false}
						scroll={{ x: "max-content" }}
						sticky
						rowSelection={{
							selectedRowKeys,
							onChange: (selectedRowKeys) => {
								setSelectedRowKeys(selectedRowKeys);
							},
						}}
					>
						{/* <Table.Column
							title="Exam Status"
							key="exam_status"
							dataIndex="exam_status"
							sorter={true}
							width={150}
						/> */}

						<Table.Column
							title="OR Number"
							key="or_number"
							dataIndex="or_number"
							sorter={true}
							width={150}
						/>

						<Table.Column
							title="Exam Category"
							key="exam_category_id"
							dataIndex="exam_category_id"
							sorter={(a, b) => a.exam_category - b.exam_category}
							render={(text, record) => {
								// console.log("record:", record);
								return <>{record.exam_category}</>;
							}}
							width={150}
						/>

						<Table.Column
							title="Exam Fee"
							key="exam_fee"
							dataIndex="exam_fee"
							sorter={(a, b) => a.exam_fee - b.exam_fee}
							width={100}
							render={(text, record) => {
								// console.log("record:", record);
								return (
									<>
										₱{" "}
										{record.exam_fee
											? parseFloat(record.exam_fee).toFixed(2)
											: "N/A"}
									</>
								);
							}}
						/>

						<Table.Column
							title="Student Year Level"
							key="student_level"
							dataIndex="student_level"
							sorter={(a, b) => a.student_level.localeCompare(b.student_level)}
							width={150}
						/>

						<Table.Column
							title="Student Status"
							key="student_status"
							dataIndex="student_status"
							sorter={(a, b) =>
								a.student_status.localeCompare(b.student_status)
							}
							width={200}
						/>

						<Table.Column
							title="Student Name"
							key="fullname"
							dataIndex="fullname"
							sorter={(a, b) => a.fullname.localeCompare(b.fullname)}
							width={200}
							render={(text, record) => {
								const fullname = record.fullname;
								return <>{` ${fullname} `}</>;
							}}
						/>

						<Table.Column
							title="Personal Email"
							key="personal_email"
							dataIndex="personal_email"
							sorter={(a, b) =>
								a.personal_email.localeCompare(b.personal_email)
							}
							width={100}
						/>
					</Table>
				</Col>
				<Col xs={24} sm={24} md={24} lg={24}>
					<div className="tbl-bottom-filter">
						<TableShowingEntriesV2 />
						<TablePagination
							tableFilter={tableFilter}
							setTableFilter={setTableFilter}
							setPaginationTotal={dataStudentExam?.data?.total}
							showLessItems={true}
							showSizeChanger={false}
							tblIdWrapper="tbl_wrapper_approval"
						/>
					</div>
				</Col>
			</Row>

			<ModalFormExamSheetNumber
				toggleModalFormExamSheetNumber={toggleModalFormExamSheetNumber}
				setToggleModalFormExamSheetNumber={setToggleModalFormExamSheetNumber}
				dataSource={dataStudentExam}
				tableFilter={tableFilter}
				selectedRowKeys={selectedRowKeys}
				setSelectedRowKeys={setSelectedRowKeys}
			/>

			<ModalAuthPassword
				toggleModalAuthPassword={toggleModalAuthPassword}
				setToggleModalAuthPassword={setToggleModalAuthPassword}
			/>
		</>
	);
}
