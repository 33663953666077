import { useEffect, useState } from "react";
import { Modal, Button, Form, notification, Col, Flex, Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputMask from "../../../../providers/FloatInputMask";
import notificationErrors from "../../../../providers/notificationErrors";
import validateRules from "../../../../providers/validateRules";
import { POST, GET } from "../../../../providers/useAxiosQuery";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatRangePicker from "../../../../providers/FloatRangePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

export default function ModalFormEntranceExamSchedule(props) {
	const {
		toggleModalFormEntranceExamSchedule,
		setToggleModalFormEntranceExamSchedule,
	} = props;

	const [form] = Form.useForm();
	const navigate = useNavigate();
	const { data: dataSemester } = GET(`api/ref_semester`, "ref_semester");
	const [refreshExamSchedule, setRefreshExamSchedule] = useState(false);

	const { mutate: mutateExamSchedule, loading: isLoading } = POST(
		`api/ref_exam_schedule`,
		"ref_exam_schedule_list"
	);

	const onFinish = (values) => {
		const sy_from = values.schoolYear[0].format("YYYY");
		const sy_to = values.schoolYear[1].format("YYYY");

		let data = {
			...values,
			sy_from,
			sy_to,
			exam_date: values.exam_date
				? values.exam_date.format("YYYY-MM-DD")
				: null,
			id:
				toggleModalFormEntranceExamSchedule.data &&
				toggleModalFormEntranceExamSchedule.data.id
					? toggleModalFormEntranceExamSchedule.data.id
					: "",
		};

		mutateExamSchedule(data, {
			onSuccess: (res) => {
				if (res.success) {
					setToggleModalFormEntranceExamSchedule({
						open: false,
						data: null,
					});
					form.resetFields();
					notification.success({
						message: "Exam Schedule",
						description: res.message,
					});

					setRefreshExamSchedule((prevState) => !prevState);
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useEffect(() => {
		if (toggleModalFormEntranceExamSchedule.open) {
			let data = { ...toggleModalFormEntranceExamSchedule.data };

			if (data.exam_date) {
				data.exam_date = dayjs(data.exam_date);
			}

			if (data.sy_from && data.sy_to) {
				data.schoolYear = [
					dayjs(`${data.sy_from}-01-01`),
					dayjs(`${data.sy_to}-01-01`),
				];
			}

			form.setFieldsValue(data);
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalFormEntranceExamSchedule, refreshExamSchedule]);

	return (
		<Modal
			title="FORM Entrance Exam Schedule"
			open={toggleModalFormEntranceExamSchedule.open}
			onCancel={() => {
				setToggleModalFormEntranceExamSchedule({
					open: false,
					data: null,
				});
				form.resetFields();
			}}
			forceRender
			footer={[
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<Tooltip
						title={
							!toggleModalFormEntranceExamSchedule.data?.id
								? "Please submit the schedule first!"
								: ""
						}
					>
						<Button
							className="btn-main-primary btn-main-invert-outline b-r-none mr-10"
							icon={<FontAwesomeIcon icon={faPlus} />}
							onClick={() => {
								navigate(
									`/applicants/walk-in/${toggleModalFormEntranceExamSchedule.data.id}/add`
								);
							}}
							name="btn_add"
							disabled={!toggleModalFormEntranceExamSchedule.data?.id}
						>
							Add Applicant
						</Button>
					</Tooltip>

					<div>
						<Button
							className="btn-main-primary outlined mr-10"
							key={1}
							onClick={() => {
								setToggleModalFormEntranceExamSchedule({
									open: false,
									data: null,
								});
								form.resetFields();
							}}
						>
							CANCEL
						</Button>
						<Button
							className="btn-main-primary"
							type="primary"
							key={2}
							onClick={() => form.submit()}
							loading={isLoading}
							name="btn_submit"
						>
							SUBMIT
						</Button>
					</div>
				</div>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Col xs={24} sm={24} md={24} lg={24}>
					<Form.Item name="schoolYear" rules={[validateRules.quillValidator]}>
						<FloatRangePicker
							label="School Year"
							placeholder="School Year"
							className="w-100 br-1 "
							picker="year"
							disabledDate={(current) =>
								current && current.year() < new Date().getFullYear()
							}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<Form.Item name="semester_id" rules={[validateRules.quillValidator]}>
						<FloatSelect
							label="Semester"
							placeholder="Semester"
							allowClear
							required={true}
							options={
								dataSemester && dataSemester.data
									? dataSemester.data.map((item) => {
											return {
												label: item.semester,
												value: item.id,
											};
									  })
									: []
							}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<Form.Item name="exam_date" rules={[validateRules.quillValidator]}>
						<FloatDatePicker
							label="Exam Day"
							placeholder="Exam Day"
							allowClear
							required={true}
							className="exam-date-picker br-1"
							format="MM-DD-YYYY"
							disabledDate={(current) =>
								current && current.endOf("day") < Date.now()
							}
						/>
					</Form.Item>
				</Col>

				<Col
					xs={24}
					sm={24}
					md={24}
					lg={24}
					style={{ display: "flex", gap: "20px" }}
				>
					<Form.Item
						name="time_in"
						rules={[
							validateRules.quillValidator,
							{
								validator: (_, value) => {
									if (!value) {
										return Promise.resolve();
									} else {
										if (value === "00:00:00" || value.includes("_")) {
											return Promise.reject(new Error("Invalid time format!"));
										}
										let hours = value ? value.split(":")[0] : 0;
										let minutes = value ? value.split(":")[1] : 0;
										let seconds = value ? value.split(":")[2] : 0;
										if (parseInt(hours, 10) >= 24) {
											return Promise.reject(
												new Error("Time cannot exceed 24 hours!")
											);
										} else if (parseInt(minutes, 10) >= 60) {
											return Promise.reject(
												new Error("Time cannot exceed 60 minutes!")
											);
										} else if (parseInt(seconds, 10) >= 60) {
											return Promise.reject(
												new Error("Time cannot exceed 60 seconds!")
											);
										}
									}
									return Promise.resolve();
								},
							},
						]}
						style={{ width: "50%" }}
					>
						<FloatInputMask
							label="Time In"
							placeholder="Time In"
							required={true}
							maskType="99:99"
							maskLabel="time_in"
						/>
					</Form.Item>

					<Form.Item
						name="time_in_meridiem"
						rules={[validateRules.quillValidator]}
						style={{ width: "50%" }}
					>
						<FloatSelect
							label="Meridiem"
							placeholder="Meridiem"
							required={true}
							options={[
								{
									label: "AM",
									value: "AM",
								},
								{
									label: "PM",
									value: "PM",
								},
							]}
						/>
					</Form.Item>
				</Col>

				<Col
					xs={24}
					sm={24}
					md={24}
					lg={24}
					style={{ display: "flex", gap: "20px" }}
				>
					<Form.Item
						name="time_out"
						rules={[
							validateRules.quillValidator,
							{
								validator: (_, value) => {
									if (!value) {
										return Promise.resolve();
									} else {
										if (value === "00:00:00" || value.includes("_")) {
											return Promise.reject(new Error("Invalid time format!"));
										}
										let hours = value ? value.split(":")[0] : 0;
										let minutes = value ? value.split(":")[1] : 0;
										let seconds = value ? value.split(":")[2] : 0;
										if (parseInt(hours, 10) >= 24) {
											return Promise.reject(
												new Error("Time cannot exceed 24 hours!")
											);
										} else if (parseInt(minutes, 10) >= 60) {
											return Promise.reject(
												new Error("Time cannot exceed 60 minutes!")
											);
										} else if (parseInt(seconds, 10) >= 60) {
											return Promise.reject(
												new Error("Time cannot exceed 60 seconds!")
											);
										}
									}
									return Promise.resolve();
								},
							},
						]}
						style={{ width: "50%" }}
					>
						<FloatInputMask
							label="Time Out"
							placeholder="Time Out"
							required={true}
							maskType="99:99"
							maskLabel="time_out"
						/>
					</Form.Item>
					<Form.Item
						name="time_out_meridiem"
						rules={[validateRules.quillValidator]}
						style={{ width: "50%" }}
					>
						<FloatSelect
							label="Meridiem"
							placeholder="Meridiem"
							required={true}
							options={[
								{
									label: "AM",
									value: "AM",
								},
								{
									label: "PM",
									value: "PM",
								},
							]}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24}>
					<Form.Item
						name="slots"
						rules={[validateRules.number, validateRules.quillValidator]}
					>
						<FloatInput
							placeholder="Slots"
							label="Slots"
							allowClear
							required={true}
						/>
					</Form.Item>
				</Col>
			</Form>
		</Modal>
	);
}
